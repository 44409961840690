import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBDropdown, MDBDropdownToggle, MDBDropdownItem, MDBDropdownMenu,
  MDBNavbar,
  MDBNavbarToggler,
  MDBNavbarNav,
  MDBNavbarLink,
  MDBCollapse
 } from 'mdb-react-ui-kit';
import useLocalStorage from 'use-local-storage';

import Index from './components/index/index';
import Projects from './components/projects';
import Teaching from './components/teaching';
import News from './components/index/news';
import Students from './components/students';
import About from './components/index/about';

import './css/main.css';

// Centralized configuration for menu and routes
const routesConfig = [
  { path: "/", label: "Home", component: Index },
  { href: "https://scholar.google.com/citations?user=MoMsVnkAAAAJ&hl=en&oi=ao", label: "Publications" },
  { path: "/projects", label: "Projects", component: Projects },
  { path: "/teaching", label: "Teaching", component: Teaching },
  { path: "/students", label: "Students", component: Students },
];

function Header() {
  const location = useLocation();
  const currentPath = location.pathname;

  const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [theme, setTheme] = useLocalStorage('theme', defaultDark ? 'dark' : 'light');
  const switchTheme = (userTheme) => {
    const newTheme = (userTheme === 'dark' || userTheme === 'light') ? userTheme : (defaultDark ? 'dark' : 'light')
    setTheme(newTheme);
    document.documentElement.dataset.mdbTheme = newTheme;
  }

  const [openBasic, setOpenBasic] = useState(false);

  return (
    <MDBNavbar expand='lg mb-3'>
      <MDBContainer >
        <MDBNavbarToggler
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
          onClick={() => setOpenBasic(!openBasic)}
        >
          <i className='icon-bars' ></i>
        </MDBNavbarToggler>

        <MDBCollapse navbar open={openBasic}>
          <MDBNavbarNav className='mb-2 mb-lg-0'>
            {routesConfig.map(({ path, label, href }) => (
              href ? (
                <MDBNavbarLink
                  key={label}
                  href={href}
                  target="_blank"
                  rel="noopener noreferrer"
                  active={currentPath === path}
                >
                  {label}
                </MDBNavbarLink>
              ) : (
                <MDBNavbarLink
                  tag={Link}
                  key={label}
                  to={path}
                  active={currentPath === path}
                  onClick={() => openBasic && setOpenBasic(!openBasic)}
                >
                  {label}
                </MDBNavbarLink>
              )
            ))}
          </MDBNavbarNav>
        </MDBCollapse>

        <MDBNavbarNav right fullWidth={false} className=''>
          <MDBDropdown>
            <MDBDropdownToggle style={{padding: "var(--mdb-navbar-toggler-padding-y) var(--mdb-navbar-toggler-padding-x)", fontSize: "var(--mdb-navbar-toggler-font-size)"}} color='link'>
              <i className={ theme === 'dark' ? 'icon-moon' : 'icon-sun' }></i>  
            </MDBDropdownToggle>
            <MDBDropdownMenu className='dropdown-menu-end' style={{minWidth:"inherit", margin: 0}}>
              <MDBDropdownItem link onClick={() => switchTheme('light')}>
                <i className='icon-sun' style={{width: "14px"}}></i> Light
              </MDBDropdownItem>
              <MDBDropdownItem link onClick={() => switchTheme('dark')}>
                <i className='icon-moon' style={{width: "14px"}}></i> Dark
              </MDBDropdownItem>
              <MDBDropdownItem link onClick={switchTheme} >
                <i className='icon-contrast' style={{width: "14px", marginRight: "3px"}}></i>Auto
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBNavbarNav>

      </MDBContainer>
    </MDBNavbar>
  );
}


function Footer() {
  return (
    <MDBFooter>
      <div className='text-center text-small p-0'>
        <small>&copy; {new Date().getFullYear()} Frederico Schardong</small>
      </div>
    </MDBFooter>
  );
}

const ScrollToHash = () => {
  const { hash } = useLocation();

  useEffect(() => {
      if (hash) {
          const targetElement = document.querySelector(hash);
          if (targetElement) {
              targetElement.scrollIntoView({ behavior: "smooth" });
          }
      }
  }, [hash]);

  return null; // This component just handles the scroll logic
};

function App() {
  const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [theme, ] = useLocalStorage('theme', defaultDark ? 'dark' : 'light');

  const hasRun = useRef(false);

  useEffect(() => {
    if (hasRun.current) return;
    hasRun.current = true;

    document.addEventListener("DOMContentLoaded", () => {
      if (window.initizalizeMobileToggle && typeof window.initizalizeMobileToggle === "function") {
        window.initizalizeMobileToggle();
      }
    });    

    document.documentElement.dataset.mdbTheme = theme;
  }, [theme]);

  return (
    <div className="d-flex flex-column min-vh-100">
      <Router>
        <ScrollToHash />
        <Header />
          <MDBContainer className="mt-1 col" >
            <MDBRow>
              <MDBCol lg="4">
                <About/>
                <News/>
              </MDBCol>
              <MDBCol lg="8">
                <Routes>
                  {routesConfig
                  .filter(({ href }) => !href) // Exclude external links
                  .map(({ path, component: Component }) => (
                    <Route key={path} path={path} element={<Component />} />
                  ))}
                </Routes>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
