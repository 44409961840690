import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBTable,
  MDBTableBody,
  MDBTooltip,
} from "mdb-react-ui-kit";

import { useLocation } from 'react-router-dom';

const newsData = [
  {
    date: "Dec 2024",
    description: "This website is released.",
  },
  {
    date: "Nov 2024",
    description: `<a href="/projects#ades">We</a> received the <a href="https://web.archive.org/web/20241205084853/https://noticias.ufsc.br/2024/11/premio-inova-ufsc-celebra-trabalhos-e-agentes-de-inovacao-da-universidade/">Inova UFSC Award</a>.`,
  },
  {
    date: "Nov 2024",
    description: `Invited by <a href="https://rnp.br/">RNP</a> to contribute to a <a href="https://www.linkedin.com/posts/redernp_recentemente-diversos-especialistas-da-rnp-activity-7270144890234171392-_BGX/">workshop</a> on decentralized digital identity.`,
  },
  {
    date: "Aug 2024",
    description: `Received the <a href="https://web.archive.org/web/20240821003415/https://digitalidadvancement.org/news/celebrating-excellence-meet-the-first-vittorio-bertocci-award-winners/">Vittorio Bertocci Award</a>.`,
  },
  {
    date: "Jul 2024",
    customRender: (
      <>
        I successfully defended my PhD thesis!
        <MDBTooltip
          className="pb-1"
          tag="a"
          wrapperProps={{
            className: "p-1",
            href: "https://www.youtube.com/live/3tDDqp-bI7s",
          }}
          title="Watch it on YouTube (Portuguese language)"
        >
          <i className="icon-youtube"></i>
        </MDBTooltip>
      </>
    ),
  },
];

// Utility function to render the content
const renderContent = (news) => {
  if (news.customRender) {
    return news.customRender;
  }
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: news.description,
      }}
    />
  );
};

const NewsRow = ({ news, isLastRow }) => (
  <tr
    style={{
      borderBottomStyle: isLastRow ? "hidden" : "solid",
    }}
  >
    <td align="right" style={{ whiteSpace: "nowrap", width: "50px" }}>
      {news.date}
    </td>
    <td align="left">{renderContent(news)}</td>
  </tr>
);

export default function News() {
  const location = useLocation();

  return (
    <MDBCard id="news" className={`mb-4 ${location.pathname !== '/' ? 'd-none d-lg-block' : ''}`}>
      <MDBCardBody>
        <MDBCardTitle>News</MDBCardTitle>
        <MDBTable small align="middle" className="mb-0">
          <MDBTableBody >
            {newsData.map((news, index) => (
              <NewsRow
                key={index}
                news={news}
                isLastRow={index === newsData.length - 1}
              />
            ))}
          </MDBTableBody>
        </MDBTable>
      </MDBCardBody>
    </MDBCard>
  );
}
