import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
  } from 'mdb-react-ui-kit';

export default function Bio() {
  return (
    <MDBCard className="mb-4">
      <MDBCardBody>
        <MDBCardTitle>Short Bio</MDBCardTitle>
        <MDBCardText>
        Frederico Schardong is an associate professor at the <a href="https://ifrs.edu.br/">Federal Institute of Science, Education and Technology of Rio Grande do Sul</a>, Brazil, where he teaches computer security, cryptography, computer networks, and programming. He holds a PhD in Computer Security from the <a href="https://ufsc.br/">Federal University of Santa Catarina</a>, Brazil. His research interests include, but are not limited to, digital identity, self-sovereign identity, digital identity protocols, and applications of digital identity such as digital signature. In addition to his academic pursuits, Frederico is leading projects related to the specification, implementation, and usage of digital identity with industry and government partners in Brazil and Mozambique.
        </MDBCardText>
      </MDBCardBody>
    </MDBCard>
  );
}