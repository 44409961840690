import React from 'react';

import Service from './service'
import Bio from './bio'
import Awards from './awards'
import SelectedPublications from './publications'

export default function Index() {
  return (
    <section>
      <Bio/>
      <SelectedPublications/>
      <Service/>
      <Awards/>
    </section>
  );
}