
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText
} from 'mdb-react-ui-kit';

export default function Index() {
  return (
    <section>
      <MDBCard name="ades" className="mb-4">
        <MDBCardBody>
          <MDBCardTitle>To be constructed</MDBCardTitle>
          <MDBCardText>
            
          </MDBCardText>
        </MDBCardBody>
      </MDBCard>
    </section>
  );
}