import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBTooltip,
} from 'mdb-react-ui-kit';

// A reusable component for rendering a single publication
function PublicationItem({ href, title, tooltip }) {
  return (
    <li>
      <a href={href}>{title}</a>
      {tooltip && (
        <MDBTooltip className="pb-1" tag="a" title={tooltip}>
          <i className="mb--1 icon-open-access"></i>
        </MDBTooltip>
      )}
    </li>
  );
}

export default function SelectedPublications() {
  const publications = [
    {
      href: 'https://doi.org/10.3390/s22155641',
      title: 'Self-Sovereign Identity: A Systematic Review, Mapping and Taxonomy',
      tooltip: 'Open Access',
    },
    {
      href: 'https://doi.org/10.1145/3605098.3636061',
      title:
        'From Self-Sovereign Identity to Fiduciary Identity: A Journey Towards Greater User Privacy and Usability',
    },
    {
      href: 'https://doi.org/10.1007/978-3-031-75872-0_20',
      title: 'The Role-Artifact-Function Framework for Understanding Digital Identity Models',
    },
  ];

  return (
    <MDBCard id="publications" className="mb-4">
      <MDBCardBody>
        <MDBCardTitle>Selected Publications</MDBCardTitle>
        <ul className="mb-0">
          {publications.map((pub, index) => (
            <PublicationItem
              key={index}
              href={pub.href}
              title={pub.title}
              tooltip={pub.tooltip}
            />
          ))}
        </ul>
      </MDBCardBody>
    </MDBCard>
  );
}
