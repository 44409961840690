import {
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBTooltip,
} from 'mdb-react-ui-kit';

import { useLocation } from 'react-router-dom';

export default function Bio() {
  const location = useLocation();

  return (
    <MDBCard className={`mb-4 ${location.pathname !== '/' ? 'd-none d-lg-block' : ''}`}>
      <MDBCardBody className="text-center">
        <MDBCardImage
          src={require('./images/self.jpg')}
          alt="avatar"
          className="rounded-circle mb-4"
          width="250"
          height="250"
          fluid />
          <p className="fw-bold mb-1">Frederico Schardong, PhD</p>
          <p className="text-muted mb-1">Associate Professor</p>
          <p className="mb-1"><a href="https://ifrs.edu.br/" >Federal Institute of Rio Grande do Sul</a></p>
          <MDBTooltip className="pb-1" tag='a' wrapperProps={{ href: 'mailto:mail@frederico.phd' }} title="Contact me">                 
            <p className="mb-1">
              <i className='icon-mail'></i> mail@frederico.phd
            </p>
          </MDBTooltip>
          <MDBTooltip className="pb-1" tag='a' wrapperProps={{ className: "p-1", href: 'https://scholar.google.com/citations?user=MoMsVnkAAAAJ&hl=en&oi=ao' }} title="Google Scholar">                 
            <i className='icon-google-scholar'></i>
          </MDBTooltip>
          <MDBTooltip className="pb-1" tag='a' wrapperProps={{ className: "p-1", href: 'https://www.linkedin.com/in/fredericoschardong/' }} title="Linkedin">
            <i className='icon-linkedin'></i>
          </MDBTooltip>
          <MDBTooltip className="pb-1" tag='a' wrapperProps={{ className: "p-1", href: 'https://github.com/fredericoschardong/' }} title="Github">
            <i className='icon-github'></i>
          </MDBTooltip>
          <MDBTooltip className="pb-1" tag='a' wrapperProps={{ className: "p-1", href: 'https://lattes.cnpq.br/8896031429725811/' }} title="Lattes">
            <i className="icon-lattes"></i>
          </MDBTooltip>
        </MDBCardBody>
    </MDBCard>
  );
}