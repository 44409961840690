import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardSubTitle,
  MDBTooltip,
} from 'mdb-react-ui-kit';

const data = [
  {
    title: "Undergraduate",
    sections: [
      {
        year: "2024",
        courses: [
          { name: "Information Security", tooltip: "66 hour course" },
          { name: "Special Topics in Computer Science", tooltip: "66 hour course" },
          { name: "Software Engineering", tooltip: "66 hour course" },
          { name: "Software Testing and Quality", tooltip: "66 hour course" },
        ],
      },
    ],
  },
  {
    title: "Professional Education",
    sections: [
      {
        year: "2024",
        courses: [
          { name: "Research Methods in Computer Science", tooltip: "66 hour course" },
        ],
      },
      {
        year: "2019",
        courses: [
          { name: "Research Methods in Computer Science", tooltip: "66 hour course" },
          { name: "Foudations of Computer Science", tooltip: "66 hour course" },
          { name: "Computer Networks", tooltip: "66 hour course" },
          { name: "Event-Driven Programming", tooltip: "100 hour course" },
        ],
      },
      {
        year: "2018",
        courses: [
          { name: "Research Methods in Computer Science", tooltip: "66 hour course" },
          { name: "Foudations of Computer Science", tooltip: "66 hour course" },
          { name: "Computer Networks", tooltip: "66 hour course" },
          { name: "Management Information System", tooltip: "2 times, 33 hour course" },
        ],
      },
      {
        year: "2017",
        courses: [
          { name: "Research Methods in Computer Science", tooltip: "66 hour course" },
          { name: "Foudations of Computer Science", tooltip: "66 hour course" },
          { name: "Management Information System", tooltip: "2 times, 33 hour course" },
        ],
      },
    ],
  },
];

export default function Index() {
  return (
    <section>
      {data.map((cardData, i) => (
        <MDBCard className="mb-4" key={i}>
          <MDBCardBody>
            <MDBCardTitle className="mb-3">{cardData.title}</MDBCardTitle>
            {cardData.sections.map((section, j) => (
              <div key={j}>
                <MDBCardSubTitle className="h6">
                  {section.year}
                </MDBCardSubTitle>
                <ul className={j === cardData.sections.length - 1 ? "mb-0" : ""}>
                  {section.courses.map((course, k) => (
                    <li key={k}>
                      <MDBTooltip tag="a" title={course.tooltip}>
                        {course.name}
                      </MDBTooltip>
                    </li>
                  ))}
                </ul>
                {j < cardData.sections.length - 1 && <hr />}
              </div>
            ))}
          </MDBCardBody>
        </MDBCard>
      ))}
    </section>
  );
}
