
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText
} from 'mdb-react-ui-kit';

export default function Index() {
  return (
    <section>
      <MDBCard id="ades" className="mb-4">
        <MDBCardBody>
          <MDBCardTitle>ADvanced Electronic Signature (ADES)</MDBCardTitle>
          <MDBCardText>
          This project aims to simplify digital certification for users who need to digitally sign documents. By leveraging an identity provider, such as Google, Meta, or LinkedIn, the signer can generate a One-Time Certificate (OTC) for each document. The OTC is issued by a certification authority within a public key infrastructure and comes with a validity period of 100 years. This effectively ensures that users can rely on their digital signatures for a lifetime without dealing with the recurring complexities of digital certification, such as renewing certificates or applying timestamps every few years before expiration.
          </MDBCardText>

          <MDBCardText>
          The project seeks to make the digital signature process more inclusive by eliminating the need for users to understand or interact with the technical details of digital certification. Current solutions often require users to manage one of the following: (i) a smartcard; (ii) a file containing the digital certificate and private key; (iii) a USB token; or (iv) a cloud certificate provider. These methods can be intimidating and cumbersome, exposing both novice and advanced users to unnecessarily complex processes. Additionally, this complexity hinders the development of APIs that could facilitate the creation of digital signatures.
          </MDBCardText>

          <a href="http://ades.labsec.ufsc.br/" target="_blank" rel="noreferrer">
            Try it out!
          </a>
        </MDBCardBody>
      </MDBCard>

      <MDBCard id="idrc" className="mb-4">
        <MDBCardBody>
          <MDBCardTitle>Identidade Eletrônica do Registro Civil (IdRC)</MDBCardTitle>
          <MDBCardText>
          To be written.
          </MDBCardText>
        </MDBCardBody>
      </MDBCard>

      <MDBCard id="mozambique" className="mb-4">
        <MDBCardBody>
          <MDBCardTitle>Mozambique's National PKI</MDBCardTitle>
          <MDBCardText>
          To be written.
          </MDBCardText>
        </MDBCardBody>
      </MDBCard>
    </section>
  );
}